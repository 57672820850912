export default class SharingUtils {
  static shareViaEmail (subject, body) {
    let encodedSubject = encodeURIComponent(subject);
    let encodedBody = encodeURIComponent(body);

    return `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
  }

  static shareViaFacebook (linkToShare, description = '') {
    let descriptionEncoded = encodeURIComponent(description);
    let linkEncoded = encodeURIComponent(linkToShare);
    return `https://www.facebook.com/sharer.php?u=${linkEncoded}&amp;description=${descriptionEncoded}`;
  }

  static shareViaTwitter (linkToShare, description = '', hashTags = '') {
    let descriptionEncoded = encodeURIComponent(description);
    let linkEncoded = encodeURIComponent(linkToShare);
    return `https://twitter.com/intent/tweet?url=${linkEncoded}&text=${descriptionEncoded}&hashtags=${hashTags}`;
  }
}
