<template>
  <div class="member-view pb-5">
    <div class="view-header mb-4">
      <p class="heading-medium-big text-secondary mb-3">Welcome back, {{ this.$store.getters['getUserFirstName'] }}</p>
      <h2 class="heading-medium-big">Invite your friends to join the Fun Fit Network!</h2>
      <p class="sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>

    <div v-if="allDataLoaded && data.invite_link !== 'no membership'">
      <template v-if="data.is_promotor">
        <div class="card card-big card-light width2-3">
          <div class="card-body">
            <div class="form-group full-width relative">
              <label>
                <span>Invite a new <em class="text-secondary text-style-normal">Promoter</em>! </span><span class="text-size-base-2">Copy this link:</span>
              </label>
              <f-input-wrapper
                class="full-width is-disabled">
                <input
                  type="text"
                  disabled="true"
                  v-model="data.invite_link"/>
                <f-button
                  theme="icon"
                  v-clipboard:copy="data.invite_link"
                  v-clipboard:success="onCopyLinkPromoterSuccess"
                  v-clipboard:error="onCopyLinkPromoterError">
                  <f-icon
                    class="icon-no-filter"
                    style="position: relative; top: 1px"
                    name="copy" />
                </f-button>
              </f-input-wrapper>
              <span
                :class="{
                  'copy-info': true,
                  'visible': inviteLinkPromoterCopyTxtVisible
                }">
                {{ inviteLinkPromoterCopyTxt }}
              </span>
            </div>
          </div>
        </div>

        <p class="flex flex-align-items-center mt-3">
          <span class="heading-small">Share to:</span>
          <a
            class="lnk lnk-icon-share mx-1"
            :href="getShareViaFacebookLink('promoter')"
            target="_blank"
            rel="nofollow noopener noreferrer">
            <f-icon
              class="icon-no-filter"
              name="facebook" />
          </a>
          <a
            class="lnk lnk-icon-share mx-1"
            :href="getShareViaTwitterLink('promoter')"
            target="_blank"
            rel="nofollow noopener noreferrer">
            <f-icon
              class="icon-no-filter"
              name="twitter" />
          </a>
          <a
            class="lnk lnk-icon-share mx-1"
            :href="getShareViaEmailLink('promoter')"
            target="_blank"
            rel="nofollow noopener noreferrer">
            <f-icon
              class="icon-no-filter"
              name="mail" />
          </a>
        </p>
      </template>
      <template v-else>
        <div class="card card-big card-light width2-3">
          <div class="card-body">
            <div class="form-group full-width mb-2 relative">
              <label>
                <span>Invite a new <em class="text-primary text-style-normal">Member</em>! </span><span class="text-size-base-2">Copy this link:</span>
              </label>
              <f-input-wrapper
                class="full-width is-disabled">
                <input
                  type="text"
                  disabled="true"
                  v-model="data.invite_link"/>
                <f-button
                  theme="icon"
                  v-clipboard:copy="data.invite_link"
                  v-clipboard:success="onCopyLinkSuccess"
                  v-clipboard:error="onCopyLinkError">
                  <f-icon
                    style="position: relative; top: 1px"
                    class="icon-no-filter"
                    name="copy" />
                </f-button>
              </f-input-wrapper>
              <span
                :class="{
                  'copy-info': true,
                  'visible': inviteLinkCopyTxtVisible
                }">
                {{ inviteLinkCopyTxt }}
              </span>
            </div>
          </div>
        </div>

        <p class="flex flex-align-items-center mt-3 mb-5">
          <span class="heading-small">Share to:</span>
          <a
            class="lnk lnk-icon-share mx-1"
            :href="getShareViaFacebookLink('member')"
            target="_blank"
            rel="nofollow noopener noreferrer">
            <f-icon
              class="icon-color-primary"
              name="facebook" />
          </a>
          <a
            class="lnk lnk-icon-share mx-1"
            :href="getShareViaTwitterLink('member')"
            target="_blank"
            rel="nofollow noopener noreferrer">
            <f-icon
              class="icon-color-primary"
              name="twitter" />
          </a>
          <a
            class="lnk lnk-icon-share ml-1"
            :href="getShareViaEmailLink('member')"
            target="_blank"
            rel="nofollow noopener noreferrer">
            <f-icon
              class="icon-color-primary"
              name="mail" />
          </a>
        </p>
      </template>
    </div>

    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
  </div>
</template>
<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';
import SharingUtils from '@/utils/SharingUtils.js';

export default {
  name: 'member-invite-friends',
  computed: {
    allDataLoaded () {
      return this.loaded;
    }
  },
  data() {
    return {
      data: {
        id: 0,
        can_invite_family: false,
        family_links: [],
        invite_link: null,
        invite_token: null,
        is_admin: false,
        is_parent_family_member: false,
        is_promotor: false,
        member_type: 'UT_MEMBER',
        membership_package: null,
        parent_id: null
      },
      loaded: false,
      loadError: false,
      inviteLinkCopyTxt: 'Copy link',
      inviteLinkCopyTxtVisible: false,
      inviteLinkPromoterCopyTxt: 'Copy link',
      inviteLinkPromoterCopyTxtVisible: false
    };
  },
  mounted() {
    this.$bus.$on('member-account-reload', this.loadItemData);
    this.loadItemData();
  },
  methods: {
    loadItemData () {
      this.loaded = false;

      FormUtils.loadItemData(this, {
        id: null,
        method: 'get',
        endpointBase: '/api/member/profile',
        instanceFields: [
          'id',
          'can_invite_family',
          'family_links',
          'invite_link',
          'invite_token',
          'is_admin',
          'is_parent_family_member',
          'is_promotor',
          'member_type',
          'membership_package',
          'parent_id'
        ],
        booleanFields: [
          'can_invite_family',
          'is_admin',
          'is_promotor',
          'newsletter',
          'policy_agreement'
        ],
        loadedKey: 'loaded'
      });
    },
    onCopyLinkSuccess () {
      Vue.set(this, 'inviteLinkCopyTxt', 'Link copied');
      Vue.set(this, 'inviteLinkCopyTxtVisible', true);

      setTimeout(() => {
        Vue.set(this, 'inviteLinkCopyTxtVisible', false);
        Vue.set(this, 'inviteLinkCopyTxt', 'Copy link');
      }, 2000);
    },
    onCopyLinkPromoterSuccess () {
      Vue.set(this, 'inviteLinkPromoterCopyTxt', 'Link copied');
      Vue.set(this, 'inviteLinkPromoterCopyTxtVisible', true);

      setTimeout(() => {
        Vue.set(this, 'inviteLinkPromoterCopyTxtVisible', false);
        Vue.set(this, 'inviteLinkPromoterCopyTxt', 'Copy link');
      }, 2000);
    },
    onCopyLinkError () {
      Vue.set(this, 'inviteLinkCopyTxt', 'Copy error');
      Vue.set(this, 'inviteLinkCopyTxtVisible', true);

      setTimeout(() => {
        Vue.set(this, 'inviteLinkCopyTxtVisible', false);
        Vue.set(this, 'inviteLinkCopyTxt', 'Copy link');
      }, 2000);
    },
    onCopyLinkPromoterError () {
      Vue.set(this, 'inviteLinkPromoterCopyTxt', 'Copy error');
      Vue.set(this, 'inviteLinkPromoterCopyTxtVisible', true);

      setTimeout(() => {
        Vue.set(this, 'inviteLinkPromoterCopyTxtVisible', false);
        Vue.set(this, 'inviteLinkPromoterCopyTxt', 'Copy link');
      }, 2000);
    },
    getShareViaFacebookLink () {
      let description = 'Have you heard about FunFit Network? Join here:';
      return SharingUtils.shareViaFacebook(this.data.invite_link, description);
    },
    getShareViaTwitterLink () {
      let description = 'Have you heard about FunFit Network? Join here:';
      return SharingUtils.shareViaTwitter(this.data.invite_link, description, 'FunFit');
    },
    getShareViaEmailLink () {
      let subject = 'Invite to FunFit Network';
      let body = `Hi,\r\nPlease use this link ${this.data.invite_link} to sign up to FunFit Network.`;
      return SharingUtils.shareViaEmail(subject, body);
    }
  },
  beforeDestroy () {
    this.$bus.$off('member-account-reload', this.loadItemData);
  }
}
</script>
